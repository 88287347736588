import { CustomerListModel } from "../customer-list/customer-list.model";
import { SiteInfoViewModel } from "../group/orgtype-wise-site.model";
import { User } from "../user-models/user";

export class StudyQueryModel {
  StartDate: string;
  EndDate: string;
  SelectedSites: SiteInfoViewModel[];
  siteData: CustomerListModel[];
  IsCloud: boolean;
  SearchOption: string;

  SearchBy: string;
  HN: string;
  Status: string;
  ModalityType: string;
  SSN: string;
  IncludeDate: boolean;
  ScoreCriteria: string;
  ScoreOperator: string;
  FirstValue: number;
  LastValue: number;
  UserId: number;
  OrderId: number;
  AccessionNo: string;
  VersionNO?: number;
  UserModel: User;
  IsDateFilter?: boolean;
  SearchValue: string;
}
